<template>
  <div class="content-wrapper">
    <div class="attendance-application main-content">
      <div class="page-header">
        <h1>My Attendance Application</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Reference ID" @search="searchApplication" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Status">
                <el-select v-model="searchForm.status" placeholder="Select the status">
                  <el-option label="Pending" value="pending"></el-option>
                  <el-option label="Accepted" value="accept"></el-option>
                  <el-option label="Rejected" value="reject"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>

        <div class="tool">
          <router-link :to="{name:'CreateAttendanceApplication'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              Create Application
            </button>
          </router-link>
        </div>
      </div>

      <div class="application-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Reference Id</th>
              <th scope="col">Status</th>
              <th scope="col">Staff Name</th>
              <th scope="col">Position</th>
              <th scope="col">Department</th>
              <th scope="col">Date</th>
              <th scope="col">Start Time</th>
              <th scope="col">End Time</th>
              <th scope="col">Created Time</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="application in applications">
              <th scope="row">{{application.reference_id}}</th>
              <td>{{application.status}}</td>
              <td>{{application.employee_name}}</td>
              <td>{{application.position}}</td>
              <td>{{application.department}}</td>
              <td>{{ formatDateTime(application.date, 'YYYY-MM-DD') }}</td>
              <td>{{ formatDateTime(application.start_time, 'HH:mm') }}</td>
              <td>{{ formatDateTime(application.end_time, 'HH:mm')}}</td>
              <td>{{ formatDateTime(application.create_time, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td class="action">
                <router-link :to="{name:'AttendanceApplication', params: {id: application.id}}">View</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// @ is an alias to /src
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'MyAttendanceApplications',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        'status': '',
      },
      originApplications: [],
      applications: [],
    }
  },
  mounted(){
    this.loadUserApplications();
  },
  methods:{
    async loadUserApplications(){
      const loginInfo = Common.getLoginInfo();
      try{
        const applications = await Hr.loadUserAttendanceApplications(this.apiUrl, loginInfo);
        this.originApplications = applications;
        this.applications = applications;
      }catch(err){
        console.log(err);
      }
    },
    searchApplication(textSearchValue){
      this.searchForm['reference_id'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newApplications = Common.filterItems(searchFilter, this.originApplications);
      this.applications = newApplications;
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.applications = this.originApplications;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.application-list{
  td{
    text-transform: capitalize;
  }
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
